const AC_GAME_OBJECTS = [];

export class AcGameObject {
    constructor() {
        AC_GAME_OBJECTS.push(this);

        this.timedelta = 0 ; // 当前这一帧距离上一帧执行的时间间隔 单位毫秒
        this.has_called_start = false;
        
    }

    start(){ // 只执行一次 创建时执行
      
    }
    
    update() { // 每一帧执行一次，除了第一帧

    }
    
    on_destroy(){  // 删除之前执行

    }

    destroy() {  //删除数组中某个对象 
        this.on_destroy();
        for(let i in AC_GAME_OBJECTS){
            const obj = AC_GAME_OBJECTS[i];
            if(obj == this){
                AC_GAME_OBJECTS.splice(i);
                break;
            }
        }

    }
}
let last_timestamp;  // 上一次执行的时刻


const step = timestamp => {
    for(let obj of AC_GAME_OBJECTS){
        if(!obj.has_called_start){
            obj.has_called_start = true;
            obj.start();
        }else {
            obj.timedelta = timestamp - last_timestamp;
            obj.update();
        }
    } // of遍历的是值 in是下标

    last_timestamp = timestamp;
    requestAnimationFrame(step); // 浏览器渲染后再执行一遍
}

requestAnimationFrame(step) //  在下一帧浏览器渲染前执行一遍