<template>

    <div>404 Not Found</div>
  </template>
  
  
  <script>
  
  </script>
  
  <style scoped>
  
  </style>