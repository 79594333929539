import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '../views/pk/PkIndexView'
import RecordIndexView from '../views/record/RecordIndexView'
import RankListIndexView from '../views/ranklist/RankListIndexView'
import UserBotsIndexView from '../views/user/bots/UserBotsIndexView'
import ErrorsView from '../views/errors/ErrorsView'
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView'
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView'
import RecordContentView from '@/views/record/RecordContentView'
import store from '@/store/index'



const routes = [
  {
    path: "/",
    name: "home",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },

  {
    path: "/pk/",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/:recordId/",
    name: "record_content",
    component: RecordContentView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist/",
    name: "ranklist_index",
    component: RankListIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bots/",
    name: "userbots_index",
    component: UserBotsIndexView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login/",
    name: "userlogin_index",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/register/",
    name: "userregister_index",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/404/",
    name: "404_error",
    component: ErrorsView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/"

  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  //可以把从 localStorage 取用户信息的过程放在 router/index.js 的 beforeEach 函数中，
  //在每次跳转路由前就不用再去请求登录页面，这样就可以每次刷新时对应的 url 也不会发生变化。
  const jwt_token = localStorage.getItem("jwt_token");

  let has_token = false;
  //如果本地浏览器有token
  if (jwt_token) {
    store.commit("updateToken", jwt_token); // 更新token
    has_token = true;
    store.dispatch("getinfo", {
      success() {
      },
      error() {
        has_token = false;
        store.state.user.show_content = true; // 数据库没有token对应的用户信息就展示登录页面
        router.push({ name: "userlogin_index" });
      }
    });
  } else {
    has_token = false;   // 
    store.state.user.show_content = true; // 没有token就展示登录页面
  }


  //如果跳转的页面需要授权,并且没有登录的话，
  if (to.meta.requestAuth && !store.state.user.is_login) {
    if (has_token) {    // 刷新页面会更改登录状态 本地还有可以对应数据库用户的令牌就可以登录
      next();
    } else {
      next({ name: "userlogin_index" }); // 跳转到登录页面
    }
  } else {
    next();   //  否则跳到默认页面
  }


})

export default router
