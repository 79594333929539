<template>
  <div
    class="userA-color"
    v-if="
      $store.state.pk.status === 'playing' &&
      parseInt($store.state.user.id) === parseInt($store.state.pk.a_id)
    "
  >
    左下角
  </div>
  <div
    class="userB-color"
    v-if="
      $store.state.pk.status === 'playing' &&
      parseInt($store.state.user.id) === parseInt($store.state.pk.b_id)
    "
  >
    右上角
  </div>
  <PlayGround v-if="$store.state.pk.status === 'playing'"></PlayGround>
  <MathchingGround
    v-if="$store.state.pk.status === 'matching'"
  ></MathchingGround>
  <ResultBoard v-if="$store.state.pk.loser != 'none'"></ResultBoard>
</template>

<script>
import PlayGround from "../../components/PlayGround.vue";
import MathchingGround from "../../components/MatchingGround.vue";
import { onMounted, onUnmounted } from "vue"; // 当页面挂载时,当页面卸载时
import { useStore } from "vuex";
import ResultBoard from "../../components/ResultBoard.vue";

export default {
  components: {
    PlayGround,
    MathchingGround,
    ResultBoard,
  },
  setup() {
    const store = useStore();
    const socketUrl = `wss://erikblog.cn/websocket/${store.state.user.token}/`;

    let socket = null;
    store.commit("updateLoser", "none");
    store.commit("updateIsRecord", false); // 将是否为录像更新为否

    onMounted(() => {
      store.commit("updateOpponent", {
        username: "我的对手",
        photo:
          "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
      });
      socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        store.commit("updateSocket", socket);
      };

      socket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);

        if (data.event === "start-matching") {
          store.commit("updateOpponent", {
            username: data.opponent_username,
            photo: data.opponent_photo,
          });

          setTimeout(() => {
            store.commit("updateStatus", "playing");
          }, 200);

          store.commit("updateGame", data.game);
        } else if (data.event === "move") {
          const game = store.state.pk.gameObject;
          const [snake0, snake1] = game.snakes;

          snake0.set_direction(data.a_direction);
          snake1.set_direction(data.b_direction);
        } else if (data.event === "result") {
          const game = store.state.pk.gameObject;
          const [snake0, snake1] = game.snakes;
          if (data.loser === "all" || data.loser === "A") {
            snake0.status = "die";
          }
          if (data.loser === "all" || data.loser === "B") {
            snake1.status = "die";
          }
          store.commit("updateLoser", data.loser);
        }
      };

      socket.onclose = () => {};
    });

    onUnmounted(() => {
      socket.close();
      store.commit("updateStatus", "matching");
    });
  },
};
</script>

<style scoped>
div.userA-color {
  text-align: center;
  color: #4876ec;
  font-size: 30px;
  font-weight: 600;
}
div.userB-color {
  text-align: center;
  color: #f94848;
  font-size: 30px;
  font-weight: 600;
}
</style>
