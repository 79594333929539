import { AcGameObject } from "./AcGameObject";
import { Snake } from "./Snake";
import { Wall } from "./Wall";

export class GameMap extends AcGameObject {
    constructor(ctx, parent, store) { // ctx：画布    parent ： 画布的父元素用来动态调整画布的长宽
        super(); // 执行父类的构造函数

        this.ctx = ctx;
        this.parent = parent;
        this.L = 0; // L表示一个格子的长度
        this.store = store;
        this.rows = 13;
        this.cols = 14;    // a蛇坐标(11,1)x+y之和偶奇...    b蛇(1,12) x+y之和奇偶... 两者不会走到同一点
        this.inner_walls_count = 20;

        this.walls = [];

        this.snakes = [
            new Snake({ id: 0, color: "#4876EC", r: this.rows - 2, c: 1 }, this),
            new Snake({ id: 1, color: "#F94848", r: 1, c: this.cols - 2 }, this),
        ];

    }

    // check_connectivity(g, sx, sy, ex,ey){ // g：地图 sx：起点x sy：起点y ex：终点x ey：终点y
    //     //用 Flood Fill 算法判断左下角是否能到右上角

    //     if(sx == ex && sy == ey) return true;

    //     g[sx][sy] = true;

    //     let dx = [-1,0,1,0], dy = [0,1,0,-1];
    //     for(let i = 0 ; i < 4 ; i++)
    //     {
    //         let a = dx[i] + sx, b = dy[i] + sy;
    //         if(!g[a][b] && this.check_connectivity(g,a,b,ex,ey))       //如果(a,b)不是墙并且可以搜到终点
    //         return true;
    //     }

    //     return false;

    // }


    create_walls() {
        // const g = [];
        // for (let i = 0; i < this.rows; i++) {
        //     g[i] = [];
        //     for (let j = 0; j < this.cols; j++) {
        //         g[i][j] = false;  // 初始化墙 ，一开始为false说明该点没有墙
        //     }
        // }
        // // 建立左右的墙
        // for (let i = 0; i < this.rows; i++) {
        //     g[i][0] = g[i][this.cols - 1] = true;
        // }
        // // 建立上下的墙
        // for (let i = 1; i < this.cols - 1; i++) {
        //     g[0][i] = g[this.rows - 1][i] = true;
        // }

        // // 创建随机障碍物
        // for(let i = 0 ; i< this.inner_walls_count / 2; i ++){
        //     for(let j = 0 ; j < 1000 ; j++){
        //         //定义行和列的随机值
        //         let r = parseInt(Math.random() * this.rows) // random() : 返回一个 [0,1)的浮点数, 乘上rows后返回[0,this.rows-1]
        //         let c = parseInt(Math.random() * this.cols) // 返回 [0,this.cols-1]
        //         if(g[r][c] || g[this.rows-1-r][this.cols - 1  -c]) continue; // 如果已经有障碍物了就跳过 对称看

        //         if(r == this.rows-2 && c == 1 || r == 1 && c == this.cols - 2) continue; //保证左下角和右上角不是障碍物
        //         g[r][c] = g[this.rows-1-r][this.cols - 1  -c] = true;
        //         break;
        //     }
        // }


        // const copy_g = JSON.parse(JSON.stringify(g)) // 复制数组，将原数组转化为JSON然后再解析回来
        // if(!this.check_connectivity(copy_g,this.rows -2,1 , 1, this.cols -2 )) return false; // 如果不连通就返回false

        const g = this.store.state.pk.gamemap;
        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                if (g[i][j]) {
                    this.walls.push(new Wall(i, j, this));
                }
            }
        }

        return true;
    }

    add_listening_events() {
        //如果是录像就放录像
        if (this.store.state.record.is_record) {
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;


            const [snake0, snake1] = this.snakes;
            const interval_id = setInterval(() => { //每300毫秒执行一次函数
                if (k >= a_steps.length - 1) { // 有一条蛇死了 直接结束放映 -1是因为不合法的操作不用展示
                    if (loser === "all" || loser === "A") {
                        snake0.status = "die";
                    }
                    if (loser === "all" || loser === "B") {
                        snake1.status = "die";
                    }
                    clearInterval(interval_id)
                    this.store.commit("updateLoser", loser);
                } else {
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;
            }, 300);

        } else {
            this.ctx.canvas.focus();   // 获得焦点

            this.ctx.canvas.addEventListener("keydown", e => {    //获得用户输入信息

                let d = -1;
                if (e.key === 'w' || e.key === 'W') d = 0;
                else if (e.key === 'd' || e.key === 'D') d = 1;
                else if (e.key === 's' || e.key === 'S') d = 2;
                else if (e.key === 'a' || e.key === 'A') d = 3;

                if (d >= 0) { // 如果获得了操作
                    this.store.state.pk.socket.send(JSON.stringify({ //把JSON变成字符串传给后端
                        event: "move",
                        direction: d,
                    }))
                }
            })
        }

    }


    start() {
        // for(let i = 0 ; i< 1000 ; i ++)  //循环1000次一般能找到合适的地图
        //  if(this.create_walls())
        //    break;

        this.create_walls();
        this.add_listening_events();

    }
    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;

    }

    check_ready() {
        for (const snake of this.snakes) {
            if (snake.status !== "idle") return false;  //如果一条蛇不处于静止，说明已经在运动或死亡
            if (snake.direction === -1) return false;  // 如果一条蛇没有接收到指令那么也不动
        }
        return true;
    }

    next_both_step() {   // 让两条蛇都进入下一步
        for (const snake of this.snakes) {
            snake.next_step();
        }
    }
    check_valid(cell) { //检测是否撞到墙

        for (const wall of this.walls) {
            if (wall.r === cell.r && wall.c === cell.c)
                return false;
        }

        for (const snake of this.snakes) {
            let k = snake.cells.length;
            if (!snake.check_tail_increasing()) { //当蛇尾会前进的时候，蛇头就可以走到刚刚蛇尾的位置
                k--; //最后一个格子不用判断
            }
            for (let i = 0; i < k; i++) {
                if (snake.cells[i].r === cell.r && snake.cells[i].c === cell.c) {
                    return false;
                }
            }
        }
        return true;
    }

    update() {
        this.update_size();
        if (this.check_ready()) {  //如果两条蛇都准备好了
            this.next_both_step(); // 让两条蛇都进入下一步
        }
        this.render();
    }


    render() {    // 渲染函数把每一个对象画到地图上
        const color_odd = "#AAD751", color_even = "#A2D149";

        for (let i = 0; i < this.rows; i++) {
            for (let j = 0; j < this.cols; j++) {
                if ((i + j) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(j * this.L, i * this.L, this.L, this.L);
            }
        }
    }

}