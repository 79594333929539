

export default {
    state: {             //定义全局变量
    is_record : false, 
    a_steps : "",
    b_steps : "",
    record_loser:"",


    },
    getters: {
    },
    mutations: {         //修改全局变量数据 用 store.commit('')调用
       updateIsRecord(state,is_record){
        state.is_record = is_record;
       },
       updateSteps(state,data){
        state.a_steps = data.a_steps;
        state.b_steps = data.b_steps;
       },
       updateRecordLoser(state,loser){
        state.record_loser = loser;
       },

    },
    actions: {

    },
    modules: {
    }
}