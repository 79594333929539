<template>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class="card" style="margin-top: 20px">
          <div class="card-body">
            <img
              :src="$store.state.user.photo"
              class="card-img-top"
              alt="..."
              style="width: 100%"
            />
          </div>
        </div>
      </div>

      <div class="col-9">
        <div class="card" style="margin-top: 20px">
          <div class="card-header">
            <span style="font-size: 150%">我的Bot</span>
            <button
              type="button"
              class="btn btn-info float-end"
              data-bs-toggle="modal"
              data-bs-target="#add-bot-btn"
            >
              创建bot
            </button>
          </div>

          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>名称</th>
                  <th>创建时间</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bot in bots" :key="bot.id">
                  <td>{{ bot.title }}</td>
                  <td>{{ bot.createtime }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-primary"
                      style="margin-right: 10px"
                      data-bs-toggle="modal"
                      :data-bs-target="'#update-bot-modal-' + bot.id"
                    >
                      修改
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="remove_bot(bot)"
                    >
                      删除
                    </button>
                    <!-- 修改bot对话框 -->
                    <div
                      class="modal fade"
                      :id="'update-bot-modal-' + bot.id"
                      tabindex="-1"
                    >
                      <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1 class="modal-title fs-5">修改bot</h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <form>
                              <div class="mb-3">
                                <label for="update-bot-title" class="form-label"
                                  >名称</label
                                >
                                <input
                                  v-model="bot.title"
                                  type="text"
                                  class="form-control"
                                  id="update-bot-title"
                                  placeholder="请输入bot名称"
                                />
                              </div>
                              <div class="mb-3">
                                <label
                                  for="update-bot-description"
                                  class="form-label"
                                  >简介</label
                                >
                                <textarea
                                  v-model="bot.description"
                                  class="form-control"
                                  id="update-bot-description"
                                  rows="3"
                                  placeholder="请输入bot简介"
                                ></textarea>
                              </div>
                              <div class="mb-3">
                                <label
                                  for="update-bot-content"
                                  class="form-label"
                                  >代码</label
                                >
                                <VAceEditor
                                  v-model:value="bot.content"
                                  @init="editorInit"
                                  lang="c_cpp"
                                  theme="textmate"
                                  style="height: 300px"
                                  :options="{
                                    enableBasicAutocompletion: true, //启用基本自动完成
                                    enableSnippets: true, // 启用代码段
                                    enableLiveAutocompletion: true, // 启用实时自动完成
                                    fontSize: 18, //设置字号
                                    tabSize: 4, // 标签大小
                                    showPrintMargin: false, //去除编辑器里的竖线
                                    highlightActiveLine: true,
                                  }"
                                />
                              </div>
                            </form>
                          </div>
                          <div class="modal-footer">
                            <div class="error_message">
                              {{ bot.error_message }}
                            </div>
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              取消
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary"
                              @click="update_bot(bot)"
                            >
                              保存修改
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 添加bot对话框 -->
  <div class="modal fade" id="add-bot-btn" tabindex="-1">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">创建bot</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="add-bot-title" class="form-label">名称</label>
              <input
                v-model="botadd.title"
                type="text"
                class="form-control"
                id="add-bot-title"
                placeholder="请输入bot名称"
              />
            </div>
            <div class="mb-3">
              <label for="add-bot-description" class="form-label">简介</label>
              <textarea
                v-model="botadd.description"
                class="form-control"
                id="add-bot-description"
                rows="3"
                placeholder="请输入bot简介"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="add-bot-content" class="form-label">代码</label>
              <VAceEditor
                v-model:value="botadd.content"
                @init="editorInit"
                lang="c_cpp"
                theme="textmate"
                style="height: 300px"
                :options="{
                  enableBasicAutocompletion: true, //启用基本自动完成
                  enableSnippets: true, // 启用代码段
                  enableLiveAutocompletion: true, // 启用实时自动完成
                  fontSize: 18, //设置字号
                  tabSize: 4, // 标签大小
                  showPrintMargin: false, //去除编辑器里的竖线
                  highlightActiveLine: true,
                }"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="error_message">
            {{ botadd.error_message }}
          </div>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            取消
          </button>
          <button type="button" class="btn btn-primary" @click="add_bot">
            提交
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { useStore } from "vuex";
import { ref, reactive } from "vue"; // 绑定变量用ref  绑定对象用reactive
import { Modal } from "bootstrap/dist/js/bootstrap";
import { VAceEditor } from "vue3-ace-editor"; // vue3 ace 在线代码编辑器
import ace from "ace-builds";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-chrome";
import "ace-builds/src-noconflict/ext-language_tools";

export default {
  components: {
    VAceEditor,
  },

  setup() {
    ace.config.set(
      "basePath",
      "https://cdn.jsdelivr.net/npm/ace-builds@" +
        require("ace-builds").version +
        "/src-noconflict/"
    );

    const store = useStore();
    let bots = ref([]);

    const botadd = reactive({
      title: "",
      description: "",
      content: "",
      error_message: "",
    });

    const refresh_bots = () => {
      $.ajax({
        url: "https://erikblog.cn/api/user/bot/getlist/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          bots.value = resp;
        },
      });
    };
    refresh_bots();

    const add_bot = () => {
      (botadd.error_message = ""), // 将上次的error_message清空
        $.ajax({
          url: "https://erikblog.cn/api/user/bot/add/",
          type: "POST",
          headers: {
            Authorization: "Bearer " + store.state.user.token,
          },
          data: {
            title: botadd.title,
            description: botadd.description,
            content: botadd.content,
          },
          success(resp) {
            if (resp.error_message === "success") {
              (botadd.title = ""),
                (botadd.description = ""),
                (botadd.content = ""),
                Modal.getInstance("#add-bot-btn").hide();
              refresh_bots();
            } else {
              botadd.error_message = resp.error_message;
            }
          },
        });
    };

    const update_bot = (bot) => {
      (botadd.error_message = ""), // 将上次的error_message清空
        $.ajax({
          url: "https://erikblog.cn/api/user/bot/update/",
          type: "POST",
          headers: {
            Authorization: "Bearer " + store.state.user.token,
          },
          data: {
            bot_id: bot.id,
            title: bot.title,
            description: bot.description,
            content: bot.content,
          },
          success(resp) {
            if (resp.error_message === "success") {
              Modal.getInstance("#update-bot-modal-" + bot.id).hide();
              refresh_bots();
            } else {
              botadd.error_message = resp.error_message;
            }
          },
        });
    };

    const remove_bot = (bot) => {
      $.ajax({
        url: "https://erikblog.cn/api/user/bot/remove/",
        type: "POST",
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          bot_id: bot.id,
        },
        success(resp) {
          if (resp.error_message === "success") {
            refresh_bots();
          }
        },
      });
    };

    return {
      bots,
      botadd,
      add_bot,
      remove_bot,
      update_bot,
    };
  },
};
</script>

<style scoped>
div.error_message {
  color: red;
}
</style>
