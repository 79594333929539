
export default {
    state: {             //定义全局变量
        status: "matching",  // 正在匹配   playing: 对战界面
        socket: null,
        opponent_username: "",   // 对手头像
        opponent_photo: "",      // 对手照片
        gamemap:null, // 地图
        a_id : 0,     // 用户A id
        a_sx : 0,     //A的起始坐标
        a_sy : 0,
        b_id : 0,     //用户B id
        b_sx : 0,     //B的起始坐标
        b_sy : 0,
        gameObject: null,
        loser :"none", // none:没人输、all、A、B 


    },
    getters: {
    },
    mutations: {         //修改全局变量数据 用 store.commit('')调用
        updateSocket(state, socket) {
            state.socket = socket;
        },
        updateOpponent(state, opponent) {
            state.opponent_username = opponent.username;
            state.opponent_photo = opponent.photo;
        },
        updateStatus(state,status){
            state.status = status;
        },
        updateGame(state,game){  // 更新地图信息与对抗双方的信息
            state.gamemap = game.map;
            state.a_id = game.a_id;
            state.a_sx = game.a_sx;
            state.a_sy = game.a_sy;

            state.b_id = game.b_id;
            state.b_sx = game.b_sx;
            state.b_by = game.b_sy;

        },
        updateGameObject(state,gameObject){
            state.gameObject = gameObject;
        },
        updateLoser(state,loser){
            state.loser = loser;
        }
    },
    actions: {

    },
    modules: {
    }
}