<template>
  <ContentField>
    <table class="table table-striped" style="text-align: center">
      <thead>
        <tr>
          <th>玩家A</th>
          <th>玩家B</th>
          <th>对战结果</th>
          <th>对战时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="record in records"
          :key="record.record.id"
          style="text-align: center"
        >
          <td>
            <img :src="record.a_photo" alt="" class="record-user-photo" />
            &nbsp;
            <span class="record-user-username">{{ record.a_name }}</span>
          </td>
          <td>
            <img :src="record.b_photo" alt="" class="record-user-photo" />
            &nbsp;
            <span class="record-user-username">{{ record.b_name }}</span>
          </td>
          <td>
            {{ record.result }}
          </td>
          <td>
            {{ record.record.createTime }}
          </td>
          <td>
            <button
              type="button"
              class="btn btn-warning btn-sm"
              @click="open_record_content(record.record.id)"
            >
              查看对局录像
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="">
      <ul class="pagination" style="float: right">
        <li class="page-item" @click="click_page(-2)">
          <a class="page-link" href="#">前一页</a>
        </li>
        <li
          :class="'page-item ' + page.is_active"
          v-for="page in pages"
          :key="page.number"
          @click="click_page(page.number)"
        >
          <a class="page-link" href="#">{{ page.number }}</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" @click="click_page(-1)">后一页</a>
        </li>
      </ul>
    </nav>
  </ContentField>
</template>

<script>
import ContentField from "../../components/ContentField";
import $ from "jquery";
import { useStore } from "vuex";
import { ref } from "vue";
import router from "../../router/index";

export default {
  components: {
    ContentField,
  },
  setup() {
    const store = useStore();
    let current_page = 1;

    let records = ref([]);
    let total_records = 0;
    let pages = ref([]); // 能点击的页码

    const click_page = (page) => {
      // 单击页码跳转
      if (page === -2) page = current_page - 1;
      else if (page === -1) page = current_page + 1;
      let max_pages = parseInt(Math.ceil(total_records / 10));

      if (page >= 1 && page <= max_pages) {
        pull_page(page);
      }
      current_page = page;
    };
    // const is_valid = page =>{  //根据是否合法判断disabled
    //     let max_pages = parseInt(Math.ceil(total_records / 10));
    //     if(page - 1 < 1 || page + 1 >max_pages) return "disabled";
    //     return "";
    // }

    const update_pages = () => {
      //求最大页面
      let max_pages = parseInt(Math.ceil(total_records / 10));
      let new_pages = [];
      //枚举当前页的前2页和后2页
      for (let i = current_page - 2; i <= current_page + 2; i++) {
        if (i >= 1 && i <= max_pages) {
          new_pages.push({
            number: i,
            is_active: i === current_page ? "active" : "",
          });
        }
      }
      pages.value = new_pages;
    };

    const pull_page = (page) => {
      $.ajax({
        url: "https://erikblog.cn/api/record/getlist/",
        type: "GET",
        data: {
          page: page,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          records.value = resp.records;
          total_records = resp.records_count;
          update_pages();
        },
        error() {},
      });
    };
    pull_page(current_page);

    //将字符串map变成地图map
    const stringTo2D = (map) => {
      let g = [];
      for (let i = 0, k = 0; i < 13; i++) {
        let line = [];
        for (let j = 0; j < 14; j++, k++) {
          if (map[k] === "0") line.push(0);
          else line.push(1);
        }
        g.push(line);
      }
      return g;
    };

    const open_record_content = (recordId) => {
      for (const record of records.value) {
        if (record.record.id === recordId) {
          store.commit("updateIsRecord", true); // 更新状态为是录像

          store.commit("updateGame", {
            //将record信息存入game对象
            map: stringTo2D(record.record.map),
            a_id: record.record.aid,
            a_sx: record.record.asx,
            a_sy: record.record.asy,

            b_id: record.record.bid,
            b_sx: record.record.bsx,
            b_sy: record.record.bsy,
          });
          store.commit("updateSteps", {
            //更新asteps和bsteps
            a_steps: record.record.asteps,
            b_steps: record.record.bsteps,
          });
          //更新输者
          store.commit("updateRecordLoser", record.record.loser);
          router.push({
            name: "record_content",
            params: {
              recordId,
            },
          });
          break;
        }
      }
    };
    return {
      pull_page,
      open_record_content,
      records,
      pages,
      click_page,
    };
  },
};
</script>

<style scoped>
img.record-user-photo {
  width: 4vh;
  border-radius: 50%;
}
</style>
