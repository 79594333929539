<template>
  <div class="matchground">
    <div class="row">
      <div class="col-4">
        <div class="user-photo">
          <img :src="$store.state.user.photo" alt="" />
        </div>
        <div class="user-username">
          {{ $store.state.user.username }}
        </div>
      </div>
      <div class="col-4">
        <div class="user-select-bot">
          <select
            class="form-select"
            aria-label="Default select example"
            v-model="select_bot"
          >
            <option value="-1" selected>亲自上阵</option>
            <option v-for="bot in bots" :key="bot.id" :value="bot.id">
              {{ bot.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-4">
        <div class="user-photo">
          <img :src="$store.state.pk.opponent_photo" alt="" />
        </div>
        <div class="user-username">
          {{ $store.state.pk.opponent_username }}
        </div>
      </div>
    </div>
    <div class="col-12" style="text-align: center; padding-top: 15vh">
      <button type="button" class="btn btn-warning btn-lg" @click="click_match">
        {{ match_bnt_info }}
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import $ from "jquery";
export default {
  setup() {
    let match_bnt_info = ref("开始匹配");
    const store = useStore();
    let bots = ref([]);
    let select_bot = ref("-1");
    const click_match = () => {
      if (match_bnt_info.value === "开始匹配") {
        match_bnt_info.value = "取消匹配";
        store.state.pk.socket.send(
          JSON.stringify({
            // 将JSON转化为字符串
            event: "start-matching", // 给后端发送一个变量表明开始匹配
            bot_id: select_bot.value,
          })
        );
      } else {
        match_bnt_info.value = "开始匹配";
        store.state.pk.socket.send(
          JSON.stringify({
            event: "stop-matching", // 给后端发送一个变量表明停止匹配
          })
        );
      }
    };
    const refresh_bots = () => {
      $.ajax({
        url: "https://erikblog.cn/api/user/bot/getlist/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          bots.value = resp;
        },
      });
    };
    refresh_bots(); // 从云端获取bot信息
    return {
      match_bnt_info,
      click_match,
      refresh_bots,
      bots,
      select_bot,
    };
  },
};
</script>

<style scoped>
div.matchground {
  width: 60vw;
  height: 70vh;
  margin: 40px auto;
  background-color: rgb(50, 50, 50, 0.4);
}

div.user-photo {
  margin-top: 120px;
  text-align: center;
}

div.user-photo > img {
  border-radius: 50%;
  width: 20vh;
}

div.user-username {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: white;
}

div.user-select-bot {
  margin-top: 200px;
}

div.user-select-bot > select {
  width: 60%;
  margin: auto;
}
</style>
